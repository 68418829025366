// Password reset handler

jQuery(document).ready(function ($) {

    $('#btn-save-password').prop("disabled", true).css('opacity', '0.3');
    $('#acf-field_61682f90c711f-field_61682f72c711e').on('keyup', function () {

        if ( $('#acf-field_61682f90c711f-field_61682f5ac711d').val() && $('#acf-field_61682f90c711f-field_61682f72c711e').val() ) {
            $('#btn-save-password').prop("disabled", false).css('opacity', '1');;
        }

    });

    if (typeof acf != "undefined") {
        //console.log(acf)
        acf.addAction('acfe/fields/button/before/name=save_password', function($el, data){
        
            $el.find('button').html('Passwort speichern <span class="spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
        
        });

        acf.addAction('acfe/fields/button/success/name=save_password', function(response, $el, data){
        
            // json success was sent
            if(response.success){

                $el.find('button').css('background-color', '#04c104');
                $el.find('button').html('Passwort gespeichert ' + '<i class="fa fa-check" aria-hidden="true"></i>')
                $el.find('button').prop("disabled",true)
                
            } else {
                var field = acf.getField('field_61682f90c711f'); // Password Group Field
                field.showNotice({
                    text: response.data,
                    type: 'warning',       // warning, error, success
                    dismiss: true,  // allow notice to be dismissed
                });
            }
            
        });

        acf.addAction('acfe/fields/button/complete/name=save_password', function(response, $el, data){
            
            response = JSON.parse(response);
            if(response.success){
                var field = acf.getField('field_61682f90c711f'); // Password Group Field
                field.showNotice({
                    text: response.data,
                    type: 'success',       // warning, error, success
                    dismiss: true,  // allow notice to be dismissed
                });
            } else {
                $el.find('button .spinner').remove()
            }
            
        });
    }

    // $('.acf-tab-wrap.-left>ul').append( $('.acf-form-submit') );

    $( ".acf-form-submit" ).clone( true, true ).appendTo( ".acf-tab-wrap.-left>ul" );


    $('.follow-button').click(function (e) {
        e.preventDefault();
        
        var $button = this;

        var form_data = new FormData();
        form_data.append('action', 'followUser');
        form_data.append('userIdToFollow', $('.follow-button').data('user') );
        form_data.append('status', $('.follow-button').data('status') );

        $.ajax({
            url: vue_wp.ajaxurl,
            type: 'POST',
            contentType: false,
            processData: false,
            data: form_data,
            beforeSend: function(xhr) {
    
            },
            success: function(response) {
    
              if (response.success == true) {
                $button.remove()
                console.log(response.data)
              } else {

              }

            }
        }).done(function() {
            console.log('done function', 'Done');
        }).always(function() {});

    });

    $('a.answer-comment').click(function (e) { 
        e.preventDefault();

        $(window).scrollTop($('.new-pinboard-entry').offset().top);

        var parentCommentID = $(this).data('parent-comment');
        var commentParentHiddenField = acf.getField('field_61e82793a6e9a');

        var $commentContent = $(this).closest('.comment')

        console.log($commentContent)

        $('.new-pinboard-entry .form-comment-answer-holder .acf-input').empty()
        $commentContent.clone( true, true ).appendTo( ".new-pinboard-entry .form-comment-answer-holder .acf-input" );

        commentParentHiddenField.val( parentCommentID )
        
    });


    $('.delete-comment').click(function (e) {
        e.preventDefault();

        if (confirm("Soll dieser Eintrag wirklich unwiderruflich gelöscht werden?") == false) {
            return;
        }
        
        var $button = this;

        var form_data = new FormData();
        form_data.append('action', 'deleteComment');
        form_data.append('commentID', $($button).data('comment-id') );

        $.ajax({
            url: vue_wp.ajaxurl,
            type: 'POST',
            contentType: false,
            processData: false,
            data: form_data,
            beforeSend: function(xhr) {
    
            },
            success: function(response) {
    
              if (response.success == true) {
                console.log(response.data)

                // Remove all child comments
                if ( $($button).closest('.comment').next().hasClass('children') ) {
                    $($button).closest('.comment').next().fadeOut()
                }

                // remove main comment
                $($button).closest('.comment').fadeOut()


              } else {

              }

            }
        }).done(function() {
            console.log('done function', 'Done');
        }).always(function() {});

    });

    $('.delete-entry').click(function (e) {
        e.preventDefault();

        if (confirm("Soll dieser Eintrag wirklich unwiderruflich gelöscht werden?") == false) {
            return;
        }

        var $button = this;

        var form_data = new FormData();
        form_data.append('action', 'deletePhotoAlbum');
        form_data.append('albumID', $($button).data('id') );

        $.ajax({
            url: fordtheme.ajaxurl,
            type: 'POST',
            contentType: false,
            processData: false,
            data: form_data,
            beforeSend: function(xhr) {
    
            },
            success: function(response) {
    
              if (response.success == true) {
                console.log(response.data)

                // Remove all child comments
                // if ( $($button).closest('.comment').next().hasClass('children') ) {
                //     $($button).closest('.comment').next().fadeOut()
                // }

                // remove main comment
                $($button).closest('tr').fadeOut()


              } else {

              }

            }
        }).done(function() {
            console.log('done function', 'Done');
        }).always(function() {});

    });

    
});
